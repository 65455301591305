import React from 'react';
import { graphql } from 'gatsby';
// Todo: Re-add when implementing product images
// import Img from 'gatsby-image';
import BackgroundImage from 'gatsby-background-image';
import { Col, Container, Row } from 'react-bootstrap';

import * as Globals from '../../../globals';
import Layout from '../../components/layout/layout';
import Seo from '../../components/seo/seo';
import ChatBanner from '../../components/chatBanner/chatBanner';
import Styles from './case-study.styles';

const SproutPage = ({ data }) => {
  let backgroundFluidImageStack = [
    data.background.childImageSharp.fluid,
    Globals.backgroundOverlay,
  ].reverse();

  return (
    <Layout>
      <Seo title="Sprout Health | Winning with beautiful content" />
      <Styles>
        <BackgroundImage Tag="header" fluid={backgroundFluidImageStack}>
          <Container>
            <Row>
              <Col
                xs={{ span: 10, offset: 1 }}
                md={{ span: 8, offset: 0 }}
                xl={{ span: 6, offset: 0 }}
              >
                <h1>
                  <small>Sprout Health</small>
                  <br />
                  Winning with high quality content
                </h1>
              </Col>
            </Row>
          </Container>
        </BackgroundImage>

        <Container className="mt-4 mt-md-5 mb-4">
          <Row className="justify-content-center">
            <Col
              xs={{ span: 11, order: 2 }}
              md={{ span: 8, order: 1 }}
              lg={{ span: 7, offset: 1 }}
            >
              <section>
                <p className="lead mb-4 mb-md-5">
                  Sprout increased its overall search traffic by more than 600%
                  in three months, earning a number one ranking for multiple
                  high-value keywords and driving revenue growth &amp; new
                  investment.
                </p>
                <h3>The Challenge</h3>
                <p>
                  When national addiction treatment company Sprout Health wanted
                  to compete with the industry's largest brands, it approached
                  Generativ to rebuild its website without sacrificing its
                  organic search ranking. The company also sought to better
                  communicate its mission, provide more informative content, and
                  convert more visitors on the website.
                </p>
                <h3>Our Approach</h3>
                <p>
                  Recognizing Sprout's dual technical and content needs, we
                  started with the foundation of search engine optimization:
                  website speed and high-quality content. Implementing the same
                  layered architecture we take to all projects, we built a new
                  website with SEO, performance, and accessibility rankings
                  >90/100 , a 30+ point improvement over the original site, and
                  a layout that prompted visitor interaction.
                </p>

                <p>
                  We also created a framework for use across other properties in
                  the Sprout Health portfolio, allowing the company to quickly
                  deploy and optimize a clean, consistent presence for every
                  facility acquired.
                </p>

                <p>
                  To achieve Sprout's content goals, we worked closely with the
                  team to understand their mission, audience and goals, then
                  developed an editorial process that would allow for both the
                  quality and scale needed to rival Sprouts' competitors. Upon
                  identifying sourced a team of experienced copywriters to
                  produce articles and resources exceeding Sprout's competition.
                </p>
              </section>

              {/* Todo: Re-add after populating product images */}
              {/*<hr className="my-5 ml-0" />*/}
            </Col>

            <Col
              xs={{ span: 11, order: 1 }}
              md={{ span: 4, order: 2 }}
              lg={{ span: 3 }}
            >
              <aside className="mb-4 mb-md-5">
                <h4>Healthcare</h4>

                <hr className="hr-small my-4 ml-0" />

                <ul>
                  <li>Design + Development</li>
                  <li>Content Marketing / SEO</li>
                  <li>Paid Search</li>
                </ul>

                <hr className="hr-small my-4 ml-0" />

                <a
                  href="https://www.sprouthealthgroup.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  sprouthealthgroup.com
                </a>
              </aside>
            </Col>
          </Row>

          {/* Todo: Add product images */}
          {/*<Row className="justify-content-center">
            <Col xs={{ span: 11 }} md={{ span: 12 }} lg={{ span: 10 }}>
              <section className="product-images">
                <h2 className="mb-3">
                  Product Headline. Lorem ipsum dolor sit amet.
                </h2>
                <Row>
                  <Col>
                    <Img fluid={data.product.childImageSharp.fluid} />
                  </Col>
                  <Col>
                    <Img fluid={data.product.childImageSharp.fluid} />
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Img fluid={data.product.childImageSharp.fluid} />
                  </Col>
                  <Col>
                    <Img fluid={data.product.childImageSharp.fluid} />
                  </Col>
                </Row>
              </section>
            </Col>
          </Row>*/}
        </Container>
      </Styles>
      <ChatBanner />
    </Layout>
  );
};

export const query = graphql`
  query {
    background: file(relativePath: { eq: "backgrounds/bg-work-shg.jpg" }) {
      ...imageFluidExtraLarge
    }
    product: file(relativePath: { eq: "placeholders/3x2.png" }) {
      ...imageFluidMedium
    }
  }
`;

export default SproutPage;
